.mute-toggle {

	&__title {
		font-weight: 300;
	}

	&__input {
		display: none;

		&:checked + .mute-toggle__label {
			text-decoration: underline;
		}
	}

	&__label {
		cursor: pointer;
	}

}
