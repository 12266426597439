@keyframes bounce {
  0%, 88%, 94%, 100% {
    transform: translateY(0);
  }
  91% {
    transform: translateY(-1rem);
  }
  97% {
    transform: translateY(-0.5rem);
  }
}

.scroll-hint {

	$link-padding: .8em;

	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99;
	transition: opacity 0.6s;

	&--hidden {
		opacity: 0;
		pointer-events: none;
	}

	&__link {
		position: absolute;
		width: 2rem;
		height: 1.15rem;
		bottom: 1rem;
		left: 0;
		right: 0;
		margin: auto;
		display: block;
		overflow: hidden;
		text-indent: -10000px;
		// bottom: $link-padding;

		background: url(../images/chevron-down.svg) no-repeat center center;
		background-size: auto 100%;

		cursor: pointer;

		animation: bounce 8s infinite;
	}
}
