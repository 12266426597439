.main-menu {

	$link-padding: .8em;

	position: fixed;
	width: 100%;
	z-index: 999;
	transition: opacity 0.6s;
	font-weight: 400;

	@include centre-children();

	&--hidden {
		opacity: 0;
	}

	&__inner {
		@include container-widths();
	}

	&__list {
		text-align: left;
		padding: 0;
		margin: 0;
		float: left;
	}

	&__item {
		display: inline-block;
		padding: 0;
		margin: 0;

		&:first-child {
			margin-left: -$link-padding;
		}
	}

	&__link {
		color: white;
		display: block;
		text-decoration: none;
		padding: $link-padding / 2;
		margin: $link-padding / 2;

		.main-menu__item--selected & {
			text-decoration: underline;
		}
	}

	&__mute-toggle {
		float: right;
		margin: $link-padding 0;
		padding-right: .2em;
	}
}
