// Import external web fonts
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);

html {
	font-size: 14px;

	@include breakpoint(small) {
		font-size: 16px;
	}

	@include breakpoint(medium) {
		font-size: 18px;
	}
	@include breakpoint(large) {
		font-size: 19px;
	}
	@include breakpoint(x-large) {
		font-size: 24px;
	}
}

// Apply main font
body {
	font-family: 'Lato', Helvetica-Neue, Helvetica, sans-serif;
	color: white;
	font-weight: 300;
}

h1 {
	font-size: 4rem;
}

h2 {
	font-size: 3.2rem;
	font-weight: 300;

	@include breakpoint(x-large) {
		font-size: 3.8;
	}
}

p {
	font-size: 1.1rem;
	letter-spacing: 0.02em;
	line-height: 1.35em;
	vertical-align: top;

	@include breakpoint(large) {
		font-size: 1rem;
	}
}

a {
	color: white;
}