// Main page container
.main-container {
	width: 100%;
	height: 100%;;
	// padding-top: 10%;
	// overflow: hidden;

	// Overwrite width of scrollmagic pin container (scroll magic positioning bug?)
	.js & > .scrollmagic-pin-spacer {
		// width: 100% !important;
		// left: 0 !important;
		// top: 0 !important;
	}
}

.main-container,
.main,
.main__body {
	height: 100%;
}

// Main content container
// .main {
// 	max-width: 1200px;
// 	position: relative;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	overflow: hidden;
// 	padding: 0 0 100px;

// 	// Without JS (and on mobile), flow down page normally
// 	.no-js & {
//     	position: relative !important;
//     }



// 	&:before {
// 		content: "";
// 		display: block;
// 		padding-top: $logo-width-ratio-perc;
// 		background: white;
// 	}
// 	&.scene-logo:before {
// 		background: transparent;
// 	}
// }

.main__body {
	position: relative;
	// transform: translateY(100%);
	// margin: 5em 0 100%;
	width: 100%;
	height: 100%;
	overflow: visible;

	.js & {
    	// opacity: 0;
    }
}

.main-section {
	min-width: 100%;
	min-height: 100%;
	overflow: hidden;

	@include centre-children();

	&__inner {
		@include container-widths();
	}
}

.js section {
	
}
