.logo-section {
	// Explicitly set full width / height
	width: 100%;
	height: 100%;
	position: relative;

	&__inner {
		position: relative;
		z-index: 1;
	}

	&__background {
		background: linear-gradient(rgba(#fff, 0.075), rgba(#fff, 0.0));
		// background: rgba(#8CEAF5, 0.4);
		opacity: 0.4;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}
