// Set html / body
html,
body {
	width: 100%;
	height: 100%;
}

html {
  background-color: black;
}

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
