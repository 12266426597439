// Logo
.logo-container {
	overflow: hidden;
}

.logo {
	position: relative;
	overflow: hidden;
	margin: 0;

	// @include envoke-gpu();

	&__image {
		display: block;
		width: 100%;
		height: auto;
	}
}
