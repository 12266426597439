.copy-section {

	// border-top: 1px solid rgba(#fff, 0.5);
	background: linear-gradient(rgba(#fff, 0.075), rgba(#fff, 0.0));

	// @include envoke-gpu();

	// Mixins specific to this module
	@mixin section-colour($colour, $perc) {
		// background: hsla(hue($colour), 30%, 40%, .7);//$background-colour-opacity);
		// background: hsla(hue($colour), 20%, 50% + 30% * (1 - $perc), .4 + .4 * $perc);//$background-colour-opacity);
		// background: hsla(hue($colour), 20%, 40%, .1);//$background-colour-opacity);
		// background: linear-gradient(rgba(#fff, 0.05), rgba(#fff, 0.0));

		// .copy-section__title {
			// color: hsl(hue($colour), 100%, 85%);
		// }
	}

	&__inner {
		padding-bottom: 3rem;
	}

	&__article {
		width: 100%;
		max-width: 400px;
		vertical-align: top;
		text-align: left;
		text-justify: distribute;

		@include breakpoint(small) {
			width: 70%;
			max-width: 36rem;
			// columns: 10rem 2;
			column-gap: 1.5em;
		}

		:first-child {
			margin-top: 0;
		}
	}

	&__title {
		max-width: 1080px;
	}

	p {
		vertical-align: top;
		margin: 0 0 1em;
		display: block;
	}
	// Force balanced columns to break-inside (default if block)
	&.copy-section--inbalanced-column p {

		display: block;
		break-inside: auto;

		&.copy-section__p-spacer {
			display: none;
		}
	}

	// Define section colours
	// $section-colours: #00B6FF, #8CEAF5;//, #19A4F4, #00EBFF;
	// $section-colours-length: length($section-colours);

	// Loop over section colours and create an nth-of-type selector for each colour in list
	// - this allows colours to be easily added / removed from the list
	// @each $current-color in $section-colours {
	// 	$i: index($section-colours, $current-color);

	// 	&:nth-of-type(#{$section-colours-length}n + #{$i}) {
	// 		@include section-colour($current-color, ($i / $section-colours-length));
	// 	}
	// }

}

