// Mobile-first breakpoint methodology adapted from:
// http://www.sitepoint.com/managing-responsive-breakpoints-sass/

// Define breakpoints
$breakpoints: (
  'small'  : 767px,
  'medium' : 992px,
  'large'  : 1200px,
  'x-large'  : 1500px
);


@mixin breakpoint($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}