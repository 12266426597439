@mixin container-widths {
	width: 90%;

	@include breakpoint(small) {
		width: 85%;
	}
	@include breakpoint(medium) {
		width: 70%;
	}
	@include breakpoint(large) {
		width: 60%;
	}
	@include breakpoint(x-large) {
		width: 50%;
	}
}

@mixin centre-children {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin envoke-gpu {
	backface-visibility: hidden;
	perspective: 1000;
	transform: translate3d(0,0,0);
	transform: translateZ(0);
}
