// video styles
.video-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
    z-index: -100;
}

// full screen background video
.video {
	z-index: 0;

	// Pre-applying filter seems to prevent lag when first applying (at least in chrome)
	filter: blur(0) brightness(1);
	transition: filter 1.5s;

	// Enable hardware acceleration to improve performance of filters
	@include envoke-gpu();

	&--background {
	    position: fixed;
	    top: 50%;
	    left: 50%;
	    z-index: -1;
	    min-width: 100%;
	    min-height: 100%;
	    width: auto;
	    height: auto;
	    transform: translateX(-50%) translateY(-50%);
	    background-repeat: no-repeat;
	    background-size: cover;
	    pointer-events: none;
	}

	&--legibility {
		filter: blur(32px) brightness(0.4);// grayscale(1);
		transition: filter 3s;
	}
}
